import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import cms from "../../components/cms/cms";

const Blog = ({ id, slug, title, date, author, excerpt }) =>
  <div>
    <p className="text-sm leading-5 text-gray-600">
      <time dateTime={date}>{date}</time>
    </p>
    <Link to={slug} className="block">
      <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
        {title}
      </h3>
      <p className="mt-3 text-base leading-6 text-gray-600 font-semibold">
        <span className="font-semibold">{author}</span>
      </p>
      <p className="mt-3 text-base leading-6 text-gray-600">
        {excerpt}
      </p>
    </Link>
    <div className="mt-3">
      <Link to={slug} className="text-base leading-6 font-semibold text-red-600 hover:text-red-500 transition ease-in-out duration-150">
        Read full story
      </Link>
    </div>
  </div>

const blogSiteTagName = cms.title.toLowerCase()

const BlogPosts = ({ data }) =>
  <Layout>
    <SEO title="Blog" />
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="py-10 px-4 sm:px-6 lg:px-8">
        <div className="relative max-w-lg mx-auto lg:max-w-7xl">
          <div>
            <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Blog
            </h2>
          </div>
          <div className="mt-6 grid gap-16 border-t-2 border-gray-100 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            {data.allMdx.nodes.filter(node => node.frontmatter.site === blogSiteTagName).map(
              ({ id, excerpt, frontmatter, fields }) => (
                <Blog key={id} id={id} slug={fields.slug} title={frontmatter.title} date={frontmatter.date} author={frontmatter.author} excerpt={excerpt} />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  </Layout >

export const query = graphql`
query SITE_INDEX_QUERY {
  allMdx(
    sort: { fields: [frontmatter___date], order: DESC }
    filter: { frontmatter: { published: { eq: true } } }
  ) {
    nodes {
      id
      excerpt(pruneLength: 250)
      frontmatter {
        title
        date(formatString: "Do MMMM YYYY")
        author,
        site
      }
      fields {
        slug
      }
    }
  }
}
`;

export default BlogPosts;